import {
  mapperModuleCharitiesSdg,
  mapperModuleMedia,
  mapperModuleMediaCard,
  mapperModuleNavigationLink,
  mapperModuleUsp,
  mapperModuleVideo,
  mapperModuleInternalVideo
  // mapperModuleChapter,
  // mapperModuleReportsListItem
} from '../modules/mappers'
import {
  mapperBlockCallToAction,
  mapperBlockCallToActionWithMedia,
  // mapperBlockCompanyFormula,
  mapperBlockContent,
  mapperBlockFaqGroup,
  mapperBlockGroup,
  mapperBlockHighlight,
  // mapperBlockKnowledgeHubChapter,
  // mapperBlockLandingMediaAndText,
  mapperBlockLogoBar,
  mapperBlockMediaCardGroup,
  mapperBlockQuote,
  mapperBlockQuoteGroup,
  // mapperBlockRelatedContent,
  // mapperBlockSimpleQuote,
  mapperBlockTabContent,
  mapperBlockTabContentLazy,
  // mapperBlockTerminalSpecs,
  // mapperBlockLandingUspList,
  mapperBlockUspList,
  // mapperBlockLandingLogoBar,
  // mapperBlockLandscapeMediThenContent,
  // mapperBlockContentCenteredTextOnly,
  // mapperBlockCampaignsMedia,
  mapperBlockNotificationBar
  // mapperBlockReportsList,
  // mapperBlockTextMetrics,
  // mapperBlockMetrics,
  // mapperBlockIndexContentGroup,
  // mapperBlockIndexChapter,
  // mapperBlockForm,
  // mapperBlockIndexHighlight,
  // mapperBlockManualContent,
  // mapperBlockLinksList
} from '../blocks/mappers'
import {
//  mapperGlobalDataArticleAuthor,
// mapperGlobalDataPersona,
// mapperGlobalDataTheme
  mapperGlobalDataCountry
} from '../global-data/mappers'
// import {
//   mapperEventAgendaSectionItem,
//   mapperEventBlockAgenda,
//   mapperEventBlockSpeakers,
//   mapperEventFormBlock,
//   mapperEventLocation,
//   mapperEventLogistics
// } from '../events/mappers'
// import {
//   mapperBlockCampaignsCallOut,
//   mapperBlockCampaignsCallToAction,
//   mapperBlockCampaignsCards,
//   mapperBlockCampaignsMetrics,
// } from '../campaigns/mappers'
import {
  mapperReportsContentBlock
} from '../reports/mappers'
import type { ContentTypeName, MapperOptions } from '../../types'

const TypeMapper: Record<ContentTypeName, Function> = {
  ModuleVideo: (data: any, options: MapperOptions) => mapperModuleVideo(data, options.imageOptions),
  ModuleInternalVideo: (data: any, options: MapperOptions) => mapperModuleInternalVideo(data, options.imageOptions),
  ModuleMedia: (data: any, options: MapperOptions) => mapperModuleMedia(data, options.imageOptions),
  ModuleMediaCard: (data: any, options: MapperOptions) => mapperModuleMediaCard(data, options),
  ModuleTable: (data: any) => data,
  ModuleNavigationLink: (data: any, options: MapperOptions) => mapperModuleNavigationLink(data, options.getUrl),
  ModuleUsp: (data: any) => mapperModuleUsp(data),
  ModuleCharitiesSdg: (data: any) => mapperModuleCharitiesSdg(data),
  ModuleChapter: (data: any) => mapperModuleChapter(data),
  BlockContent: (data: any, options: MapperOptions) => mapperBlockContent(data, options),
  BlockGroup: (data: any, options: MapperOptions) => mapperBlockGroup(data, options),
  BlockQuote: (data: any, options: MapperOptions) => mapperBlockQuote(data, options),
  BlockCallToAction: (data: any, options: MapperOptions) => mapperBlockCallToAction(data, options.getUrl),
  BlockLogoBar: (data: any, options: MapperOptions) => mapperBlockLogoBar(data, options),
  BlockLandingLogoBar: (data: any, options: MapperOptions) => mapperBlockLandingLogoBar(data, options),
  BlockMediaCardGroup: (data: any, options: MapperOptions) => mapperBlockMediaCardGroup(data, options),
  BlockTabContent: (data: any, options: MapperOptions) => mapperBlockTabContent(data, options),
  BlockTabContentLazy: (data: any, options: MapperOptions) => mapperBlockTabContentLazy(data),
  BlockUspList: (data: any, options: MapperOptions) => mapperBlockUspList(data),
  BlockFaqGroup: (data: any, options: MapperOptions) => mapperBlockFaqGroup(data),
  BlockCompanyFormula: (data: any, options: MapperOptions) => mapperBlockCompanyFormula(data, options.transformShortDescription),
  GlobalDataArticleAuthor: (data: any, options: MapperOptions) => mapperGlobalDataArticleAuthor(data, options.imageOptions),
  GlobalDataPersona: (data: any, options: MapperOptions) => mapperGlobalDataPersona(data, options.imageOptions),
  BlockTerminalSpecs: (data: any, options: MapperOptions) => mapperBlockTerminalSpecs(data),
  BlockKnowledgeHubChapter: (data: any, options: MapperOptions) => mapperBlockKnowledgeHubChapter(data, options),
  BlockHighlight: (data: any, options: MapperOptions) => mapperBlockHighlight(data, options),
  BlockQuoteGroup: (data: any, options: MapperOptions) => mapperBlockQuoteGroup(data, options),
  EventLocation: (data: any, options: MapperOptions) => mapperEventLocation(data),
  EventAgendaSectionItem: (data: any, options: MapperOptions) => mapperEventAgendaSectionItem(data),
  EventBlockAgenda: (data: any, options: MapperOptions) => mapperEventBlockAgenda(data),
  EventFormBlock: (data: any, options: MapperOptions) => mapperEventFormBlock(data),
  EventBlockSpeakers: (data: any, options: MapperOptions) => mapperEventBlockSpeakers(data, options.imageOptions),
  EventLogistics: (data: any, options: MapperOptions) => mapperEventLogistics(data, options.imageOptions),
  BlockRelatedContent: (data: any, options: MapperOptions) => mapperBlockRelatedContent(data, options),
  BlockSimpleQuote: (data: any, options: MapperOptions) => mapperBlockSimpleQuote(data, options.imageOptions),
  BlockLandingMediaAndText: (data: any, options: MapperOptions) => mapperBlockLandingMediaAndText(data, options),
  BlockCampaignsMetrics: (data: any, options: MapperOptions) => mapperBlockCampaignsMetrics(data),
  BlockCampaignsCards: (data: any, options: MapperOptions) => mapperBlockCampaignsCards(data, options.getUrl),
  BlockCampaignsCallOut: (data: any, options: MapperOptions) => mapperBlockCampaignsCallOut(data, options),
  BlockCampaignsCallToAction: (data: any, options: MapperOptions) => mapperBlockCampaignsCallToAction(data, options.getUrl),
  BlockCallToActionWithMedia: (data: any, options: MapperOptions) => mapperBlockCallToActionWithMedia(data, options),
  BlockTerminalSpecsLazy: (data: any) => (data),
  ReportsContentBlock: (data: any, options: MapperOptions) => mapperReportsContentBlock(data, options.getUrl),
  BlockLandscapeMediaThenContent: (data: any, options: MapperOptions) => mapperBlockLandscapeMediThenContent(data, options),
  BlockContentCenteredTextOnly: (data: any, options: MapperOptions) => mapperBlockContentCenteredTextOnly(data, options),
  BlockLandingUspList: (data: any) => mapperBlockLandingUspList(data),
  BlockKnowledgeHubTopic: (data: any) => defaultReturnMapper(data),
  GlobalDataTopic: (data: any) => defaultReturnMapper(data),
  GlobalDataArticleType: (data: any) => defaultReturnMapper(data),
  GlobalDataIndustry: (data: any) => defaultReturnMapper(data),
  GlobalDataProduct: (data: any) => defaultReturnMapper(data),
  GlobalDataCountry: (data: any) => mapperGlobalDataCountry(data),
  PageIndustrySubscriptionPayments: (data: any) => defaultReturnMapper(data),
  PageIndustryDigitalPayments: (data: any) => defaultReturnMapper(data),
  PageIndustryMobilityPayments: (data: any) => defaultReturnMapper(data),
  PageIndustryRetailPayments: (data: any) => defaultReturnMapper(data),
  PageIndustryHotelPayments: (data: any) => defaultReturnMapper(data),
  PageIndustryFoodBeveragePayments: (data: any) => defaultReturnMapper(data),
  GlobalDataTheme: (data: any) => mapperGlobalDataTheme(data),
  BlockCampaignsMedia: (data: any, options: MapperOptions) => mapperBlockCampaignsMedia(data, options),
  BlockNotificationBar: (data: any, options: MapperOptions) => mapperBlockNotificationBar(data, options),
  BlockReportsList: (data: any, options: MapperOptions) => mapperBlockReportsList(data, options),
  ModuleReportsListItem: (data: any, options: MapperOptions) => mapperModuleReportsListItem(data, options),
  BlockTextMetrics: (data: any, options: MapperOptions) => mapperBlockTextMetrics(data, options),
  BlockMetrics: (data: any) => mapperBlockMetrics(data),
  BlockIndexChapter: (data: any, options: MapperOptions) => mapperBlockIndexChapter(data, options),
  BlockIndexContentGroup: (data: any, options: MapperOptions) => mapperBlockIndexContentGroup(data, options),
  BlockForm: (data: any, options: MapperOptions) => mapperBlockForm(data, options.getUrl),
  BlockIndexHighlight: (data: any, options: MapperOptions) => mapperBlockIndexHighlight(data, options),
  BlockManualContent: (data: any, options: MapperOptions) => mapperBlockManualContent(data, options),
  BlockLinksList: (data: any, options: MapperOptions) => mapperBlockLinksList(data, options)
}

const defaultReturnMapper = (data: any) => {
  return data
}
export const BASE_TYPES = new Set([
  'String', 'Integer', 'Array', 'Number', 'Boolean', 'Symbol', 'Text', 'Date', 'Location'
])
const isBaseType = (type: string) => {
  return BASE_TYPES.has(type)
}

export const useTypeMappers = (layerMappers: any) => {
  const mapperForType = (type: string): Function => {
    if (isBaseType(type)) {
      return defaultReturnMapper
    }

    let mapper = TypeMapper[type] || layerMappers?.TypeMapper?.[type]
    if (!mapper) {
      if (type !== undefined) {
        // eslint-disable-next-line no-console
        console.warn(`${type} not defined in type-mappers`)
      }
      mapper = defaultReturnMapper
    }

    return mapper
  }

  return {
    mapperForType
  }
}
