import mapperPage from '../data-layer/pages/page/mapper-page'
import mapperDynamicPage from '../data-layer/pages/page/mapper-dynamic-page'
import mapperDynamicEntry from '../data-layer/dynamic/mapper-entry-dynamic'
import type { MapperOptions } from '../types'

export const useDataMapper = () => {
  const { getI18nFormatterLocale } = useLocales()
  const { locale } = useI18n()
  const { labels } = useGlobalData()
  const store = useRoutesStore()

  const dataPageMapper = (data: any, layerMappers: any) => {
    const { getUrl, getInternalUrl } = useRoutes(store.routes, locale)
    return mapperPage(data, {
      formatter: getI18nFormatterLocale,
      getInternalUrl,
      getUrl,
      locale: locale.value,
      labels: labels.value
    }, layerMappers)
  }

  const dataDynamicPageMapper = (data: any, options: MapperOptions, layerMappers: any) => {
    const { getUrl, getInternalUrl } = useRoutes(store.routes, locale)
    return mapperDynamicPage(data, {
      ...options,
      locale: locale.value,
      formatter: getI18nFormatterLocale,
      getInternalUrl,
      getUrl,
      labels: labels.value
    }, layerMappers)
  }

  /**
   *
   * @param {Object} data - query page data
   * @param {Object} options
   * @returns {Object} - normalized page data based on the content type mapper
   */
  const dataDynamicEntryMapper = (data: any, options: MapperOptions, layerMappers: any) => {
    const { getUrl, getInternalUrl } = useRoutes(store.routes, locale)

    return mapperDynamicEntry(data, {
      ...options,
      locale: locale.value,
      formatter: getI18nFormatterLocale,
      getInternalUrl,
      getUrl,
      labels: labels.value
    }, layerMappers)
  }


  return {
    retrieveRoutes: store.retrieveRoutes,
    retrieveAllRoutes: store.retrieveAllRoutes,
    dataPageMapper,
    dataDynamicPageMapper,
    dataDynamicEntryMapper
  }
}
