import { useNumber } from '../../../../utils/useNumber'
const { convertTo } = useNumber()

const quantityAbbreviation = {
  billion: 'BN',
  million: 'MN'
}

/**
 * @function normalizeQuantity - Evens all chart data to the same base (Million, Billion) in case it's not already homogenous
 * @param {Object[]} financialData - Financial data list to normalize
 * @param {String} financialData[].period
 * @param {Number} financialData[].year
 * @param {Number} financialData[].processedVolumeAmount
 * @param {String} financialData[].processedVolumeQuantity
 * @param {Number} financialData[].netRevenueAmount
 * @param {String} financialData[].netRevenueQuantity
 * @param {Number} financialData[].ebitdaAmount
 * @param {String} financialData[].ebitdaQuantity
 * @param {String} key - The data to normalize (can either be processedVolume, netRevenue or ebitda)
 */
const normalizeQuantity = (financialData: any, key: String) => {
  const quantityKey = `${key}Quantity`
  const amountKey = `${key}Amount`
  const targetQuantityValue = financialData[0][quantityKey]
  return financialData.map((el: any) => ({
    period: `${el.period} ${el.year}`,
    value: el[quantityKey] !== targetQuantityValue ? convertTo(el[amountKey], targetQuantityValue) : el[amountKey],
    quantityValue: quantityAbbreviation[targetQuantityValue.toLowerCase()]
  }))
}

/**
 * financialsFinancialRecapCollection, normalize a collection of Financials - Financial Recap data
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */
export default ({ fullYear = null, halfYear = null, period = '' }: any) => {
  if (!fullYear) {
    return null
  }

  let halfYearData

  const fullYearData = fullYear?.items.length ? [...fullYear.items] : []
  fullYearData.reverse()

  if (halfYear) {
    // Financial Items for Half Year can have 2 display options:
    //
    // ## Option 1: Show last 4 half year data matching the current period
    // Meaning that if current period is `H1 2024` we need to get [H1-2021, H1-2022, H1-2023, H1-2024]
    //
    // ## Option 2: Show last 4 half year periods straight away
    // Meaning that if current period is `H1 2024` we need to get [H2-2022, H1-2023, H2-2023, H1-2024]

    // if true -> Option 1 | false -> Option 2
    // @TODO: move this config to the CMS
    const SHOW_ONLY_CURRENT_PERIOD_DATA = false

    let halfYearItems = []

    if (SHOW_ONLY_CURRENT_PERIOD_DATA) {
      // Option 1
      const currentPeriod = period || halfYear?.items?.[0].period
      // We get from the CMS last 8 half year items and we filter to get the last 4 from the current period
      halfYearItems = halfYear?.items
        ?.filter((item) => {
          return item.period === currentPeriod
        })
        .slice(0, 4)
    } else {
      // Option 2
      halfYearItems = halfYear?.items?.slice(0, 4)
    }

    halfYearData = halfYearItems?.length ? [...halfYearItems] : []
    halfYearData.reverse()
  } else {
    // This is the case of the annual report where halfYear items do no exist
    halfYearData = fullYearData
  }

  return {
    hasOnlyFullYearData: !halfYear,
    fullYearData,
    halfYearData,
    chartData: halfYearData.length
      ? {
          processedVolume: normalizeQuantity(halfYearData, 'processedVolume'),
          netRevenue: normalizeQuantity(halfYearData, 'netRevenue'),
          ebitda: normalizeQuantity(halfYearData, 'ebitda')
        }
      : {}
  }
}
